.patient-page .page-header {
    flex-direction: column;
}
.patient-page .patients-search {
    width: 100%;
}
.patient-page .search-input-wrapper {
    width: 100%;
    max-width: none;
}

.bread-crumbs-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: -32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #181827;
}
@media (max-width: 1300px) {
    .bread-crumbs-wrapper {
        margin-bottom: 0;
    }
}
.bread-crumbs-link {
    color: #999999;
}
.bread-crumbs-more {
    font-size: 16px;
    margin: 0 4px;
}