.profile-wrapper {
    position: relative;
    width: 100%;
    z-index: 2;
}
@media (max-width: 1300px) {
    .profile-wrapper {
        max-width: 210px;
        margin-left: auto;
    }
}
.profile {
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 16px;
    cursor: pointer;
}
@media (max-width: 1300px) {
    .profile {
        padding: 10px;
    }
}
.profile-wrapper:hover .profile {
    background-color: rgba(255,255,255,0.1);
    border: 1px solid transparent;
}
.profile-img-wrapper,
.profile-img-wrapper svg,
.profile-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.profile-data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 0 5px 10px;
    overflow: hidden;
}
.profile-full-name {
    font-weight: 700;
    font-size: 11px;
    line-height: 15px;
    color: #FFFFFF;
    overflow: hidden;
    width: 100%;
}
.profile-specialization {
    font-weight: 500;
    font-size: 9px;
    line-height: 12px;
    color: #9A975A;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.profile-action-wrapper {
    display: none;
    position: absolute;
    padding-top: 8px;
    width: 100%;
}
.profile-action {
    width: 100%;
    border: none;
    padding: 16px 16px 16px 48px;
    background-color: #C25E6B;
    box-shadow: 0 6px 24px rgba(173, 180, 173, 0.35);
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    color: white;
    cursor: pointer;
}
.profile-wrapper:hover .profile-action-wrapper,
.profile-action-wrapper:hover {
    display: block;
}
.profile-action svg {
    position: absolute;
    left: 16px;
}