.sign-up-password-label {
    margin-bottom: 32px;
}
.consent {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-top: 12px;
    max-width: 315px;
}
.consent a {
    color: #9A975A;
}
.consent-and {
    display: inline-block;
    margin: 0 4px;
}


.registration-popup-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}
.registration-popup-wrapper > svg {
    width: 240px;
    height: 24px;
}
.registration-popup-wrapper .popup {
    width: 480px;
    padding: 40px 54px;
    margin-top: 40px;
}
.sign-up-highlight {
    color: #214C4F;
}
.didnt-receive {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
    margin-bottom: 8px;
}
.sign-up-resend {
    background-color: transparent;
    border: none;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #94B4A4;
}
.valid-for {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #C9C9C9;
}