.change-language-buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.change-language-button {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    background-color: transparent;
    padding: 0;
}
.change-language-button:hover {
    color: #94B4A4;
}
.change-language-button--active {
    color: #94B4A4;
    padding-right: 26px;
}
.change-language-button--active:after {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.16602 9.99998L8.33268 14.1666L16.666 5.83331' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 0;
    top: 1px;
}
.change-language-separator {
    display: block;
    width: .5px;
    height: 18px;
    transform: scaleX(2);
    background-color: #181827;
    margin: 0 16px;
}