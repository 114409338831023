.settings-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .settings-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}

.settings-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.8);
    border-radius: 50%;
}
.settings-header-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    color: #181827;
    margin-left: 12px;
}
.settings-content-wrapper {
    padding: 48px 24px;
}

.tab-section {
    border-top: 1px solid #F0F0F0;
    padding: 24px 0;
}
.tab-content-wrapper .tab-section:last-of-type {
    padding-bottom: 0;
}
.tab-label {
    display: block;
    margin-bottom: 16px;
    max-width: 400px;
}
.tab-label-text {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #181827;
    margin-bottom: 12px;
}
.tab-select,
.tab-input {
    width: 400px;
    padding: 13px 18px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
}
.tab-input:hover {
    border-color: #C9C9C9;
}
.tab-input:focus {
    border-color: #94B4A4;
}
.tab-select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center right 14px;
}
.tab-select.tab-select--not-chosen {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%23999999' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    color: #999999;
    font-weight: 500;
}
.tab-label .tab-select ~ .field-wrapper,
.tab-label .field-wrapper ~ .field-wrapper {
    margin-top: 12px;
}
.field-wrapper--error .tab-input {
    border-color: #C25E6B;
    color: #C25E6B;
}
.field-inner-wrapper {
    display: inline-block;
    position: relative;
}
.field-wrapper--error .field-inner-wrapper:after {
    content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z' fill='%23C25E6B' stroke='%23C25E6B' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 6.66675V10.0001' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10 13.3333H10.0073' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    right: -30px;
    top: 50%;
    transform: translateY(-50%);
}
.tab-button {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 229px;
    width: 100%;
    padding: 13px 17px;
    text-align: center;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    border: 1px solid #94B4A4;
    color: #94B4A4;
    position: relative;
    overflow: hidden;
}
.tab-button:hover {
    color: white;
    border: none;
    padding: 14px 6px;
}
.tab-button.tab-button--save {
    color: white;
    background-color: #94B4A4;
    border-color: transparent;
}
.tab-button.tab-button--save:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.tab-button.tab-button--delete {
    color: white;
    background: #C25E6B;
    border-color: transparent;
    margin: 0 auto;
}
.tab-button.tab-button--delete:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.tab-button.tab-button--transparent {
    background-color: transparent;
    border: 1px solid #94B4A4;
    color: #94B4A4;
    justify-content: flex-start;
    margin-top: 16px;
}
.tab-button.tab-button--transparent:hover {
    border: none;
    padding: 14px 18px;
    background: #94B4A4;
    color: white;
}
.tab-button--transparent svg {
    position: absolute;
    top: 14px;
    left: 17px;
}
.tab-button--transparent path {
    stroke: #94B4A4;
}
.tab-button--transparent:hover path {
    stroke: white;
}

.popup-email {
    color: #94B4A4;
}