.creation-account-photo-wrapper {
    margin-top: 32px;
    margin-bottom: 72px;
}
.creation-account-photo-wrapper .photo-label {
    margin: 0 auto;
    background: url("../../images/creation-account-photo-empty.svg");
}
.creation-account-photo-wrapper .photo-wrapper {
    margin: 0 auto;
}