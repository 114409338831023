.main-nav {
    display: flex;
    flex-direction: column;
}
.main-header .main-nav {
    margin: 40px 0;
}
@media (max-width: 1300px) {
    .main-header .main-nav {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 500px;
        min-height: 100vh;
        justify-content: center;
        align-items: center;
        transform: translateY(-100%);
        margin: 0;
        background-image: url(../../images/mobile-menu-background.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: transform 1s;
        padding-top: 88px;
    }
}
.main-header--menu-opened .main-nav {
    transform: translateY(0);
}
.nav-item {
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px 16px 16px 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: none;
    color: white;
    border-radius: 16px;
}
@media (max-width: 1300px) {
    .nav-item {
        width: 280px;
        padding: 16px 0;
        justify-content: center;
        font-size: 20px;
        line-height: 27px;
        margin: 8px 0;
    }
}
.nav-item:not(.nav-item--active):hover {
    color: #94B4A4;
}
.nav-item--active {
    font-weight: 600;
    background-color: #FFFFFF;
    color: #181827;
}
.nav-item svg {
    position: absolute;
    left: 16px;
}
.nav-item:not(.nav-item--active):hover path {
    stroke: #94B4A4;
}
.nav-item--active path {
    stroke: #181827;
}
.nav-item-notifications-wrapper {
    position: relative;
}
.nav-item-notifications {
    display: flex;
    justify-content: center;
    align-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    top: -2.5px;
    right: -53px;
    font-weight: 500;
    font-size: 11px;
    line-height: 24px;
    color: #FFFFFF;
    background-color: #94B4A4;
    border-radius: 50%;
}
@media (max-width: 1300px) {
    .nav-item-notifications {
        width: 20px;
        height: 20px;
        line-height: 20px;
        top: 4px;
        right: -28px;
    }
}
.nav-item-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    font-size: 11px;
    line-height: 14px;
    background-color: #94B4A4;
    color: white;
    margin-left: auto;
    border-radius: 50%;
    margin-top: -2px;
    margin-bottom: -2px;
}
@media (max-width: 1300px) {
    .nav-item-count {
        width: 20px;
        height: 20px;
        margin-left: 6px;
        margin-top: 0;
        margin-bottom: 0;
    }
}