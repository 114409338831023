.notification {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    bottom: 48px;
    width: 290px;
    padding: 35px 24px 24px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(173, 180, 173, 0.35);
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    z-index: 1300;
}
.notification:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 12px;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
    border-top-right-radius: 12px;
}
.notification-error:before {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #C25E6B;
}
.notification-target-text {
    display: inline-block;
    margin-right: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
}
.notification-target-action {
    display: inline-block;
    max-width: 197px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.notification-error .notification-target-action {
    color: #C25E6B;
}
.notification svg {
    cursor: pointer;
    float: right;
    width: 16px;
    height: 16px;
}
.notification path {
    stroke: #181827;
}