.patient-rehabilitation-program-wrapper {
    margin-top: 40px;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-program-wrapper {
        margin-top: 64px;
    }
}
.patient-rehabilitation-program-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 24px;
}
.patient-rehabilitation-program-stages-wrapper {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    padding: 32px;
    border-radius: 24px;
}
.patient-rehabilitation-program-stage {
    display: flex;
    align-items: center;
    position: relative;
    padding: 16px 0;
}
.patient-rehabilitation-program-stage:first-of-type {
    padding-top: 0;
}
.patient-rehabilitation-program-stage:last-of-type {
    padding-bottom: 0;
}
.patient-rehabilitation-program-stage:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: linear-gradient(90deg, rgba(240, 240, 240, 0) -1.02%, #F0F0F0 50.54%, rgba(240, 240, 240, 0) 100%);
}
.patient-rehabilitation-program-stage:last-of-type:after {
    content: none;
}
.patient-rehabilitation-program-stage-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
    background-color: #EFF4F1;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #94B4A4;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-number {
    background-color: #F0F0F0;
    color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-number {
    background-color: rgba(33, 76, 79, 0.1);
    color: #214C4F;
}
.patient-rehabilitation-program-stage-status {
    display: flex;
    position: relative;
    padding: 5px 14px 5px 26px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #94B4A4;
    border-radius: 60px;
    margin-right: 12px;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-status {
    background-color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-status {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #214C4F;
}
.patient-rehabilitation-program-stage-status:before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z' fill='white'/%3E%3C/svg%3E%0A");
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-status:before {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6L5 8.5L10 3.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-status:before {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6L9.5 6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.5 9L9.5 6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6.5 3L9.5 6' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");}
.patient-rehabilitation-program-stage-load {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #214C4F;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-stage-load {
    color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-stage-load {
    color: #214C4F;
}
.patient-rehabilitation-program-stage-load-value {
    display: inline-block;
    font-weight: bold;
    margin-left: 8px;
}
.patient-rehabilitation-program-weeks-wrapper {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    margin-left: auto;
    padding: 5px 12px;
    border-radius: 16px;
    background-color: #EFF4F1;
    color: #94B4A4;
}
.patient-rehabilitation-program-stage--over .patient-rehabilitation-program-weeks-wrapper {
    background-color: #F0F0F0;
    color: #C9C9C9;
}
.patient-rehabilitation-program-stage--next .patient-rehabilitation-program-weeks-wrapper {
    background-color: rgba(33, 76, 79, 0.1);
    color: #214C4F;
}
.patient-rehabilitation-program-weeks-wrapper span:last-of-type {
    display: inline-block;
    margin-left: 4px;
}

.patient-rehabilitation-details-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 24px;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-details-wrapper {
        flex-wrap: wrap;
    }
}

.patient-rehabilitation-charts-wrapper {
    position: relative;
    padding: 84px 32px 64px;
    background-color: #FFFFFF;
    border-radius: 24px;
    width: 756px;
    flex-shrink: 0;
    overflow: auto;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-charts-wrapper {
        width: 100%;
        flex: 100%;
    }
}
.chart-small-items-wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
    width: 618px;
    margin: 0 auto;
}
.dash {
    position: absolute;
    width: 8px;
    height: 1px;
    background-color: #181827;
    border-radius: 4px;
}
.dash-top-left {
    left: -28px;
}
.dash-top-right {
    right: -28px;
}
.dash-bottom-right {
    right: -28px;
    bottom: 0;
}
.chart-small-note {
    position: absolute;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #181827;
}
.chart-small-note-bottom-left {
    left: -32px;
    bottom: -48px;
}
.chart-small-note-bottom-right {
    right: -32px;
    bottom: -48px;
}
.chart-small-percent {
    position: absolute;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    color: #181827;
    left: -32px;
    bottom: 0;
}
.chart-small-percent.right {
    left: auto;
    right: -32px;
    transform: translateY(50%);
}


.patient-rehabilitation-current-load-wrapper {
    display: flex;
    width: 322px;
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 23px;
    padding: 27px 24px;
}
@media (max-width: 1300px) {
    .patient-rehabilitation-current-load-wrapper {
        width: 100%;
        flex: 100%;
        margin-top: 12px;
    }
}
.patient-rehabilitation-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 52px;
    width: 52px;
    height: 52px;
    margin-right: 20px;
    border: 6px solid #EFF4F1;
    border-radius: 50%;
}
.patient-rehabilitation-icon-wrapper.c100 {
    width: 52px;
    height: 52px;
}
.patient-rehabilitation-icon-wrapper.c100 .slice {
    width: 52px;
    height: 52px;
    clip: rect(0, 52px, 52px, 26px);
}
.patient-rehabilitation-icon-wrapper.c100 .fill,
.patient-rehabilitation-icon-wrapper.c100 .bar {
    width: 52px;
    height: 52px;
    clip: rect(0, 26px, 52px, 0);
    border: 6px solid #94B4A4;
}
.patient-rehabilitation-icon-wrapper svg {
    position: relative;
    width: 18px;
    height: 18px;
}
.patient-rehabilitation-icon-wrapper path {
    stroke: #94B4A4;
}
.patient-rehabilitation-current-load {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: #181827;
    margin-bottom: 5px;
}
.patient-rehabilitation-current-load-button {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
}
.patient-rehabilitation-current-load-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    margin-left: 4px;
    text-align: left;
    /*white-space: nowrap;*/
    color: #9A975A;
    /*border-bottom: 1px solid #9A975A;*/
}
@media (max-width: 1300px) {
    .patient-rehabilitation-current-load-inner-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}
