@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap');

*,
*:before,
*:after {
    box-sizing: border-box;
    outline: none;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}
body {
    margin: 0;
    padding: 0;
    font-family: 'Manrope', sans-serif;
    overflow-x: auto;
    background-color: #F7FBF9;
}
input,
select,
button,
a {
    font-family: 'Manrope', sans-serif;
    border: none;
    outline: none;
}
button {
    cursor: pointer;
}
input {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
}
input::placeholder,
textarea::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
}
input:hover {
    border-color: #C9C9C9;
}
input:focus {
    border-color: #94B4A4;
}
#root {
    min-height: 100vh;
    background-color: #FAFAFA;
}
.field-wrapper--error input {
    border-color: #C25E6B;
    color: #C25E6B;
}
.button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding: 12px 31px;
    background-color: transparent;
    border: 1px solid transparent;
    user-select: none;
    cursor: pointer;
}
.button-success {
    background-color: #94B4A4;
    border-color: #94B4A4;
    color: white;
}
.button-success:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.button-disabled {
    background-color: #F0F0F0;
    border-color: #F0F0F0;
    color: white;
    pointer-events: none;
}
.button-wrapper {
    width: 100%;
}
.button-wrapper--loading {
    position: relative;
}
.button-wrapper--loading:after {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    right: -34px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg class='loader-icon' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='display:block;' width='24px' height='24px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' r='30' stroke-width='10' stroke='%2394b4a4' stroke-dasharray='47.12388980384689 47.12388980384689' fill='none' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0 50 50;360 50 50'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
}
.button-outline-success {
    border-color: #94B4A4;
    color: #94B4A4;
}
.button-outline-success:hover {
    background-color: #94B4A4;
    color: white;
}
.button-outline-success path {
    stroke: #94B4A4;
}
.button-outline-success:hover path {
    stroke: white;
}
.button-outline-disabled {
    border-color: #F0F0F0;
    color: #F0F0F0;
    pointer-events: none;
}
.button-outline-secondary {
    border-color: #F0F0F0;
    color: #181827;
}

.events-none {
    pointer-events: none;
}
.tab-label.events-none,
.events-none .tab-label-text,
.events-none .tab-input,
.events-none .tab-input::placeholder {
    color: #F0F0F0;
}
.full-width {
    width: 100%;
}
.loader-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 5px;
}