.loader-wrapper {
    height: 100%;
}
.loader-progress {
    height: 6px;
    width: 0;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
    animation: loader-progress-width 4s cubic-bezier(0.4, 0, 1, 1) forwards, loader-progress-width2 infinite, loader-progress-width2 60s 4s ease-out forwards;
}
.loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader svg {
    max-width: 80%;
    animation: loader-opacity 1s infinite alternate cubic-bezier(0.4, 0, 1, 1);
}
@keyframes loader-opacity {
    from {
        opacity: 1;
    }
    to {
        opacity: .3;
    }
}
@keyframes loader-progress-width {
    from {
        width: 0;
    }
    to {
        width: 75%;
    }
}
@keyframes loader-progress-width2 {
    from {
        width: 75%;
    }
    to {
        width: 97%;
    }
}