#photo {
    display: none;
}
.photo-label {
    display: block;
    background-image: url(../../images/change-photo-empty-icon.svg);
    width: 150px;
    height: 168px;
    padding-bottom: 168px;
    background-color: transparent;
    color: transparent;
    cursor: pointer;
}
.photo-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    margin-top: 16px;
}
.photo-container {
    display: flex;
    justify-content: flex-start;
}
.photo-wrapper {
    background-image: url("data:image/svg+xml,%3Csvg width='150' height='150' viewBox='0 0 150 150' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='75' cy='75' r='74.5' stroke='url(%23paint0_linear)'/%3E%3Ccircle cx='75' cy='75' r='65' fill='url(%23paint1_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='75' y1='0' x2='75' y2='140' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2394B4A4'/%3E%3Cstop offset='1' stop-color='%2394B4A4' stop-opacity='0'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear' x1='75' y1='10' x2='75' y2='140' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23EFF4F1'/%3E%3Cstop offset='1' stop-color='%23EFF4F1' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}
.photo-wrapper:after {
    content: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='24' cy='24' r='24' fill='%2394B4A4'/%3E%3Cpath d='M17.334 30.6667H20.6673L29.4173 21.9167C29.8593 21.4747 30.1077 20.8751 30.1077 20.25C30.1077 19.6249 29.8593 19.0254 29.4173 18.5834C28.9753 18.1413 28.3758 17.893 27.7507 17.893C27.1255 17.893 26.526 18.1413 26.084 18.5834L17.334 27.3334V30.6667Z' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M25.25 19.4167L28.5833 22.7501' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    display: block;
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
}
.photo {
    border-radius: 50%;
    width: 130px;
    height: 130px;
}
.remove-photo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 34px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #C25E6B;
    background-color: transparent;
}
.remove-photo svg {
    margin-right: 8px;
}
.photo-error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    margin-top: 67px;
    margin-left: 27px;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #C25E6B;
}
.photo-error svg {
    margin-right: 6px;
}