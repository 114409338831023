.popup-title.current-load-style {
    margin-top: 0;
}
.current-load {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 137px;
    height: 48px;
    border: 1px solid #F0EFE6;
    border-radius: 12px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #9A975A;
    margin: 32px auto;
}
.change-load-notice {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-bottom: 12px;
}
.current-load-percents-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #CCCBAC;
    margin: 3px 0 30px;
}


.progress-wrapper {
    position: relative;
    height: 32px;
    width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='4' height='4' viewBox='0 0 4 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='2' cy='2' r='2' fill='%239A975A'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: 24.8px 4px;
    background-repeat: repeat no-repeat;
    overflow: hidden;
}
.progress-wrapper:before {
    content: '';
    position: absolute;
    display: block;
    background-color: #F0EFE6;
    width: 330px;
    height: 6px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border-radius: 10px;
    z-index: -1;
}
.progress-button {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: white;
    top: 50%;
    transform: translateY(-50%);
    border: 10px solid #9A975A;
    padding: 0;
}
.progress-button:before {
    content: '';
    position: absolute;
    height: 6px;
    width: 330px;
    background-color: #9A975A;
    left: -330px;
    top: 50%;
    transform: translateY(-50%);
}
.progress-button:after {
    content: '';
    position: absolute;
    height: 6px;
    width: 330px;
    right: -330px;
    top: 50%;
    transform: translateY(-50%);
}