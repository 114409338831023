@media (max-width: 1300px) {
    .otp-number-component {
        padding: 48px 80px;
        margin-top: 40px;
        background-color: white;
        box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
        border-radius: 24px;
        position: relative;
    }
}
.otp-number-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #181827;
    margin-top: 24px;
    margin-bottom: 48px;
}
@media (max-width: 1300px) {
    .otp-number-title {
        margin-top: 0;
    }
}
.otp-number-next-button-wrapper {
    margin-top: 48px;
}

.otp-number-consent {
    margin: 12px auto 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999;
    max-width: 326px;
}
.otp-number-consent a {
    color: #9a975a;
}
@media (max-width: 1300px) {
    .otp-number-ledge {
        background-color: white;
        box-shadow: 0 6px 24px rgb(233 235 233 / 35%);
        height: 24px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        position: absolute;
        bottom: -24px;
        width: 88%;
        left: 6%;
    }
}

