.chart-small-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
}
.chart-small-canvas-wrapper {
    display: inline-block;
    border-radius: 12px;
    overflow: hidden;
}
.chart-small-step-over {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 5px 14px 5px 26px;
    background-color: #C9C9C9;
    border-radius: 60px;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: #FFFFFF;
}
.chart-small-step-over:before {
    content: '';
    position: absolute;
    display: block;
    width: 12px;
    height: 12px;
    top: 10px;
    left: 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 6L5 8.5L10 3.5' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.chart-small-weeks-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translate(-50%, 100%);
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    color: #94B4A4;
    white-space: nowrap;
}
.chart-small-wrapper--over .chart-small-weeks-wrapper {
    color: #C9C9C9;
}
.chart-small-wrapper--next .chart-small-weeks-wrapper {
    color: #214C4F;
}
.chart-small-weeks-wrapper svg {
    margin-right: 4px;
}
.chart-small-wrapper--over path {
    stroke: #C9C9C9;
}
.chart-small-wrapper--next path {
    stroke: #214C4F;
}
.chart-small-step-current {
    position: absolute;
}
.chart-small-step-current:before {
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 83px;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='83' viewBox='0 0 16 83' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 16L8 83' stroke='%239A975A' stroke-dasharray='3 3'/%3E%3Ccircle cx='8' cy='8' r='7.5' fill='%239A975A' stroke='%239A975A'/%3E%3Ccircle cx='8' cy='8' r='3' fill='white'/%3E%3C/svg%3E%0A");
}
.chart-small-step-current-text {
    position: absolute;
    text-align: center;
    top: -22px;
    font-weight: bold;
    font-size: 13px;
    line-height: 18px;
    color: #9A975A;
    transform: translate(-50%, -100%);
    white-space: nowrap;
}
.chart-small-step-current-text--offset-left {

}