.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}
.login-field-wrapper {
    padding: 10px;
}

.forgot-password {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #9A975A;
    text-decoration-line: none;
    display: block;
    margin-top: 8px;
    margin-bottom: 32px;
}

.new-user {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
}
.new-user-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    margin-right: 6px;
}
.new-user-link {
    display: flex;
    align-items: flex-end;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #94B4A4;
    text-decoration-line: none;
}
.new-user-link svg {
    margin-left: 4px;
    margin-bottom: 1px;
}