.support-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .support-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}

.support-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.8);
    border-radius: 50%;
}
.support-header-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    color: #181827;
    margin-left: 12px;
}


.support-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 70%;
    padding: 24px 12px;
    margin-top: 48px;
}
.main-layout .support-content-wrapper {
    margin-top: 0;
}
.support-content-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #181827;
    margin: 24px auto 12px;
}
.support-content-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
}
.links-wrapper {
    margin-top: 48px;
    max-width: 582px;
    width: 100%;
}
.links-separator {
    height: 1px;
    background: linear-gradient(270deg, rgba(240, 240, 240, 0) 0%, #F0F0F0 50.83%, rgba(240, 240, 240, 0) 100%);
    margin: 32px 0 24px;
}
.links-wrapper .group-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.link-button-wrapper {
    position: relative;
    width: 229px;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
}
.link-button-wrapper a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 13px 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-decoration-line: none;
}
.link-button-wrapper svg {
    margin-right: 9px;
}
.link-button-gap {
    width: 16px;
}
.telegram-link-wrapper {
    background-color: #5EB5F7;
}
.telegram-link-wrapper:hover {
    box-shadow: 0 6px 10px rgba(150, 183, 208, 0.35);
}
.viber-link-wrapper {
    background-color: #7B519D;
}
.viber-link-wrapper:hover {
    box-shadow: 0 6px 10px rgba(179, 153, 201, 0.35);
}
.whatsapp-link-wrapper {
    background-color: #25E47A;
}
.email-link-wrapper {
    background-color: #94B4A4;
}
.support-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration-line: none;
}
.support-link-text {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #94B4A4;
}
.support-link-text:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #94B4A4;
}
.phone-icon-wrapper,
.mail-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border: 1px solid #C9D9D1;
    border-radius: 50%;
    margin-right: 12px;
}
.support-link:hover .phone-icon-wrapper,
.support-link:hover .mail-icon-wrapper {
    background-color:  #94B4A4;
    border-color: #94B4A4;
}
.support-link-gap {
    width: 32px;
}
.support-link:hover .phone-icon-wrapper path {
    fill: white;
}
.support-link:hover .mail-icon-wrapper path:first-of-type {
    fill: white;
}
.support-link:hover .mail-icon-wrapper path:last-of-type {
    stroke: #94B4A4;
}