.initial-screen-wrapper {
    display: flex;
    background-color: #F7FBF9;
    height: 100%;
    min-height: 100vh;
    min-width: 1440px;
}
@media (max-width: 1300px) {
    .initial-screen-wrapper {
        min-width: auto;
    }
}
.initial-screen-data-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    min-width: 600px;
    padding: 56px 120px;
    background-color: white;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}
@media (max-width: 1300px) {
    .initial-screen-data-wrapper {
        width: 100%;
        padding: 120px 48px 48px;
        background-color: transparent;
    }
}
.initial-screen-data-wrapper--flex-start {
    align-items: flex-start;
}
.initial-screen-data-wrapper label {
    display: block;
    text-align: left;
    margin-bottom: 24px;
}
.initial-screen-data-wrapper input {
    width:  360px;
    padding: 13px 18px;
}
.initial-screen-data-wrapper .is-button {
    position: relative;
    width:  360px;
    background-color: #94B4A4;
    color: white;
    border-radius: 12px;
    text-align: center;
    padding: 13px 18px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    overflow: hidden;
}
.initial-screen-data-wrapper .is-button.disabled {
    background-color: #F0F0F0;
    user-select: none;
    pointer-events: none;
}
.initial-screen-data-container {
    max-width: 360px;
    width: 100%;
}
@media (max-width: 1300px) {
    .initial-screen-data-container {
        max-width: 520px;
    }
}
.initial-screen-logo {
    display: block;
    margin: 0 auto;
}
.initial-screen-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #181827;
    margin-top: 40px;
    margin-bottom: 32px;
}
.initial-screen-data-separator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    margin-top: 16px;
    margin-bottom: 12px;
}
.initial-screen-data-separator:before,
.initial-screen-data-separator:after {
    content: '';
    display: block;
    width: 152px;
    height: 1px;
    background: linear-gradient(90deg, rgba(243, 243, 243, 0) 0%, #F3F3F3 48.23%, rgba(243, 243, 243, 0) 100%);
}



.initial-screen-decor-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 65%;
    min-width: 840px;
}
.language-wrapper {
    position: absolute;
    top: 48px;
    right: 56px;
}
@media (max-width: 1300px) {
    .language-wrapper {
        right: 48px;
    }
}
.language-wrapper:after {
    content: '';
    display: block;
    position: absolute;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 7.5L10 12.5L15 7.5' stroke='%2394B4A4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    width: 20px;
    height: 20px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}
.language-wrapper:focus-within:after {
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12.5L10 7.5L15 12.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
#language {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #94B4A4;
    padding: 12px 39px 12px 17px;
    background-color: transparent;
    border-radius: 12px;
    border: 1px solid #C9D9D1;
    appearance: none;
}
#language:focus {
    background-color: #94B4A4;
    color: #FFFFFF;
}
#language option {
    background-color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.initial-screen-decor-wrapper svg,
.initial-screen-decor-wrapper img {
    margin-right: -30px;
    width: min(100%, 100vh);
    min-width: 808px;
    height: auto;
}
.initial-screen-decor-title {
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #181827;
    margin-top: -20px;
    margin-bottom: 24px;
}
.initial-screen-decor-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    text-align: center;
    color: #999999;
    max-width: 438px;
}

.initial-screen-support-link-wrapper {
    position: absolute;
    top: 48px;
    right: 48px;
}
@media (max-width: 1300px) {
    .initial-screen-support-link-wrapper {
        right: 207px;
    }
}