.patients-header {
    display: flex;
    justify-content: space-between;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .patients-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}

.patients-count {
    display: flex;
    align-items: center;
}
.patients-count-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.8);
    border-radius: 50%;
}
.patients-count-data {
    padding-left: 12px;
}
.patients-count-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    color: #181827;
    margin-bottom: 2px;
}
.patients-count-info {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #999999;
}


.header-action-wrapper {
    display: flex;
    align-items: center;
}


.patient-add {
    position: relative;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding: 13px 32px;
    border-radius: 12px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
}
.header__patient-add {
    margin-left: 16px;
    color: #FFFFFF;
    background-color: #94B4A4;
}
.header__patient-add:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}
.patients-list-empty__patient-add {
    background-color: transparent;
    border: 1px solid #94B4A4;
    color: #94B4A4;
}
.patients-list-empty__patient-add:hover {
    border: none;
    padding: 14px 32px;
    background: #94B4A4;
    color: white;
}
.patient-add svg {
    margin-right: 8px;
}
.patients-list-empty__patient-add path {
    stroke: #94B4A4;
}
.patients-list-empty__patient-add:hover path {
    stroke: white;
}


.patients-list-wrapper {
    margin: 0 40px 40px;
}
.patients-list-item {
    display: flex;
    position: relative;
    background-image: url(../../images/patients-list-item-background.svg);
    width: 100%;
    height: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 24px 24px 77px 24px;
    cursor: pointer;
    border-radius: 24px;
    margin-top: -53px;
    filter: drop-shadow(1px 0 0 #F0F0F0) drop-shadow(0 1px 0 #F0F0F0) drop-shadow(-1px 0 0 #F0F0F0) drop-shadow(0 -1px 0 #F0F0F0) drop-shadow(0px -6px 24px rgba(233, 235, 233, 0.35))
}
.patients-list-item:hover {
    filter: drop-shadow(1px 0 0 #F0F0F0) drop-shadow(0 1px 0 #F0F0F0) drop-shadow(-1px 0 0 #F0F0F0) drop-shadow(0 -1px 0 #F0F0F0) drop-shadow(0px -6px 24px rgba(233, 235, 233, 0.9))
}
.patients-list-item:first-of-type {
    margin-top: 0;
}
.patients-list-item:last-of-type {
    padding-bottom: 24px;
}
.patients-list-item-group {
    display: flex;
    width: 50%;
}
.patient-photo-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 104px;
    height: 104px;
}
.patient-photo-wrapper img {
    width: 104px;
    height: 104px;
    border-radius: 50%;
}
.patient-status {
    display: inline-block;
    position: absolute;
    bottom: 0;
    padding: 6px 12px;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
    border-radius: 24px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}
.patient-status-payment,
.patient-status-start_at {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #94B4A4;
}
.patient-status-in_progress {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
}
.patient-status-canceled {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #C25E6B;
}
.patient-status-completed {
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #214C4F;
}
.patient-data-wrapper {
    padding-left: 24px;
}
.patient-fullname {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: #181827;
}
.patient-details-wrapper {
    margin: 8px 0;
}
.patient-details-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}
.patient-details-text-operation {
    display: inline-block;
    margin-left: 16px;
    margin-right: 3px;
}
.patient-dates {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}
.patient-diagnosis {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.patient-program-container {
    justify-content: space-between;
}
.patient-program-wrapper {
    margin-left: 20%;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #C9C9C9;
}
.patient-program-wrapper--in-progress {
    color: #94B4A4;
}
.patient-program-item {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
}
.patient-program-wrapper--in-progress .patient-program-item--successful {
    color: #94B4A4;
}
.patient-program-wrapper--in-progress .patient-program-item--unsuccessful {
    color: #C25E6B;
}
.patient-program-wrapper--in-progress .patient-program-item--successful path {
    stroke: #94B4A4;
}
.patient-program-wrapper--in-progress .patient-program-item--unsuccessful path {
    stroke: #C25E6B;
}
.patient-program-item:last-of-type {
    margin-bottom: 0;
}
.patient-program-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 26px;
    width: 26px;
    height: 26px;
    margin-right: 12px;
    border: 3px solid #F0F0F0;
    border-radius: 50%;
}
.patient-program-note {
    display: inline-block;
    margin-left: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #C9C9C9;
}
.delete-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.delete-icon-wrapper:hover {
    background-color: rgba(194, 94, 107, 0.05);
}
.delete-icon-wrapper:active {
    background-color: rgba(194, 94, 107, 0.1);
}


.patients-list-empty-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
}
.patients-list-empty-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #9A975A;
    margin: 18px 0 32px;
}


.patients-filtered-list-empty-title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #181827;
    margin-bottom: 12px;
}
.patients-filtered-list-empty-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #181827;
}