.creation-account-step-wrapper--3 .tab-label {
    margin-bottom: 24px;
}
.creation-account-tabs-wrapper {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    height: 39px;
    margin: 32px 0 34px;
}
.creation-account-tabs-wrapper:before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 480px;
    bottom: 0;
    left: -40px;
    background-color: #F3F3F3;
}
.creation-account-tab {
    padding-bottom: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color:  #C9C9C9;
    background-color: transparent;
}
.creation-account-tab:hover {
    color: #94B4A4;
}
.creation-account-tab--chosen {
    position: relative;
    color: #94B4A4;
    font-weight: 800;
}
.creation-account-tab--chosen:before {
    content: '';
    position: absolute;
    display: block;
    height: 5px;
    width: 80px;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #94B4A4;
    border-radius: 4px;
}

.creation-account-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.card-number-wrapper .field-wrapper--error .field-inner-wrapper:after {
    right: -166px;
}
.card-number-wrapper,
.card-number-wrapper .tab-input {
    width: 264px;
}
.card-expire-wrapper,
.card-expire-wrapper .tab-input {
    width: 120px;
}
.creation-account-card .tab-label {
    margin-bottom: 0;
}