.cbm-button-wrapper {
    width: 100%;
}
.cbm-button-wrapper--loading {
    position: relative;
}
.cbm-button-wrapper--loading:after {
    content: '';
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    top: 50%;
    right: -34px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg class='loader-icon' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='display:block;' width='24px' height='24px' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid'%3E%3Ccircle cx='50' cy='50' r='30' stroke-width='10' stroke='%2394b4a4' stroke-dasharray='47.12388980384689 47.12388980384689' fill='none' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0 50 50;360 50 50'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
}

.cbm-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    padding: 12px 31px;
    background-color: transparent;
    border: 1px solid transparent;
    user-select: none;
    cursor: pointer;
}

.cbm-button-full-width {
    width: 100%;
}

.cbm-button-success {
    background-color: #94B4A4;
    border-color: #94B4A4;
    color: white;
}
.cbm-button-success:hover {
    box-shadow: 0 6px 10px rgba(162, 198, 195, 0.35);
}

.cbm-button-outline {
    border-color: #94B4A4;
    color: #94B4A4;
}

.cbm-button--disabled {
    background-color: #F0F0F0;
    border-color: #F0F0F0;
    color: white;
    pointer-events: none;
}