.tab-wrapper {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 16px;
    max-width: 662px;
    width: 100%;
}
.settings-content-wrapper .tab-wrapper {
    margin: 0 auto 16px;
}


.tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 21px 32px 22px;
    cursor: pointer;
    user-select: none;
}
.tab-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #181827;
}
.tab-wrapper--active .tab-header svg {
    transform: rotate(180deg);
}


.tab-content-wrapper {
    padding: 0 32px 32px;
}