.patients-search .search-input-wrapper:hover,
.patients-search .search-input-wrapper:focus-within {
    border: 1px solid #C9C9C9;
}
.patients-search .search-input-wrapper svg {
    width: 20px;
    height: 20px;
}
.patients-search .search-input-wrapper svg:first-of-type:last-of-type {
    cursor: text;
}
.patients-search .search-input-wrapper svg:last-of-type {
    cursor: pointer;
}
.patients-search .search-input-wrapper svg path {
    stroke: #94B4A4;
}
.patients-search .search-result-wrapper:first-of-type {
    margin-bottom: 16px;
}
.patients-search .search-result-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
}
.search-result-item-diagnosis {
    line-height: 16px;
    align-items: flex-start;
}
.patients-search .search-result-item img,
.patients-search .search-result-item svg {
    flex: 0 0 40px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 12px;
}
.search-result-small {
    white-space: nowrap;
    margin-right: 3px;
    font-weight: 500;
    font-size: 12px;
    color: #999999;
}