.creation-account-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px 24px;
}

.creation-account-content-wrapper {
    margin-top: 40px;
    max-width: 480px;
    width: 100%;
}
.creation-account-content-header {
    padding: 32px 40px 24px;
    background-image: url("../../images/header-waves-bg.svg");
    background-color: #181827;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}
.creation-account-content-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #FFFFFF;
}
.creation-account-content-step-info {
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #9A975A;
    margin-top: 4px;
}
.creation-account-progressbar {
    position: relative;
    height: 8px;
    background-image: url("../../images/creation-account-progressbar-bg.svg");
}
.creation-account-progressbar-gradient {
    position: absolute;
    display: block;
    height: 8px;
    top: 0;
    left: 0;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
}
/*.creation-account-progressbar:before {
    content: '';
    position: absolute;
    display: block;
    height: 8px;
    width: 120px;
    background: radial-gradient(162.96% 359.81% at 14.53% 64.81%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #9A975A;
}
.creation-account-progressbar--1:before {
    width: 240px;
}
.creation-account-progressbar--2:before {
    width: 360px;
}
.creation-account-progressbar--3:before {
    width: 480px;
}*/
.creation-account-step-wrapper {
    padding: 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}
.creation-account-ledge {
    height: 24px;
    width: 90%;
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
}


.creation-account-separator {
    background: linear-gradient(90deg, rgba(243,243,243,0) 0%, #F3F3F3 50%, rgba(243,243,243,0) 100%);
    height: 1px;
    width: 100%;
    margin: 32px 0 24px;
}
.creation-account-section-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: #181827;
}
.creation-account-section-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    margin-top: 10px ;
}
.creation-account-controls-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 48px;
}
.creation-account-controls-wrapper button:first-of-type {
    width: 159px;
}
.creation-account-controls-wrapper button:last-of-type {
    width: 229px;
}
.creation-account-back-icon {
    margin-right: 8px;
    height: 22px;
}
.creation-account-label-icon {
    margin-right: 6px;
}
.creation-account-block {
    margin: 26px 0 32px;
}
