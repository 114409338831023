.search-input-wrapper {
    display: flex;
    align-items: center;
    padding: 13px 14px;
    width: 400px;
    min-width: 283px;
    max-width: 400px;
    border: 1px solid #F0F0F0;
    border-radius: 12px;
    cursor: text;
}
@media (max-width: 1300px) {
    .search-input-wrapper.patients {
        width: calc(100% - 80px);
        max-width: 100%;
        margin: 16px 40px 0;
    }
}
.search-input-wrapper:focus-within {
    border-color: #94B4A4 !important;
}
.search-input {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    border: none;
    margin-left: 12px;
    border-radius: 0;
    background-color: transparent;
}
.search-result-container-wrapper {
    display: none;
    position: absolute;
    padding-top: 8px;
    width: 100%;
    z-index: 10;
}
@media (max-width: 1300px) {
    .search-result-container-wrapper {
        width: calc(100% - 48px);
        margin: 0 24px;
    }
}
.drop-down-wrapper:focus-within .search-result-container-wrapper,
.search-result-container-wrapper:hover {
    display: block;
}
.search-result-container {
    background-color: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.7);
    border-radius: 12px;
    padding: 16px;
}
.search-result-item {
    margin: 12px 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    color: #181827;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.search-result-item:hover {
    color: #94B4A4;
}