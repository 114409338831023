.delete-workplace {
    display: flex;
    align-items: center;
    background-color: transparent;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    color: #C25E6B;
    margin-top: 24px;
}
.delete-workplace svg {
    margin-right: 8px;
}

.add-workplace-wrapper {
    display: flex;
    align-items: center;
}
.add-workplace-wrapper svg {
    margin-right: 12px;
}
.add-workplace-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    max-width: 348px;
}
.add-workplace-button-text {
    text-align: center;
    width: 100%;
}

.jobs-search .search-result-container {
    padding: 16px 13px;
    max-height: 250px;
    overflow: auto;
}
.jobs-search .search-result-item {
    margin-bottom: 16px;
}
.jobs-search .search-result-img-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #F0F0F0;
    border-radius: 50%;
    margin-right: 12px;
    flex: 0 0 32px;
}
.jobs-search .search-result-img-wrapper svg {
    flex: 0 0 16px;
    width: 16px;
    height: 16px;
    margin: 0;
}
.jobs-search .search-result-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #181827;
    margin-bottom: 4px;
}
.jobs-search .search-result-item:hover .search-result-title {
    color: #94B4A4;
}
.jobs-search .search-result-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #999999;
}

#experience.tab-input {
    width: 78px;
    text-align: center;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.work-experience-note {
    display: inline-block;
    width: 78px;
    padding: 13px 27px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
    border: 1px solid #F0F0F0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    margin-left: -1px;
}
#experience.tab-input:hover ~ .work-experience-note {
    border-color: #C9C9C9;
}
#experience.tab-input:focus ~ .work-experience-note {
    border-color: #94B4A4;
}
.field-wrapper--error #experience.tab-input ~ .work-experience-note {
    border-color: #C25E6B;
}