.tab-button.remove-account {
    max-width: 100% ;
}
.remove-account-cancel {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #94B4A4;
    margin-top: 24px;
    cursor: pointer;
}
.remove-account-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.gap-12 {
    width: 12px;
    flex: 0 0 12px;
}
.popup-remove-account .popup-text {
    max-width: 250px;
}