.main-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 290px;
    /*height: clamp(100%, 100vh, 100vh);*/
    /*min-height: 100vh;*/
    height: 100vh;
    /*overflow: hidden;*/
    background-color: #181827;
    background-image: url(../../images/main-header-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 48px 56px 48px 24px;
}
@media (max-width: 1300px) {
    .main-header {
        max-width: 100%;
        flex: 1 1 100%;
        min-height: auto;
        padding: 14px 24px;
        max-height: 88px;
        z-index: 301;
        position: relative;
        background-image: url(../../images/main-header-mobile-background.png);
    }
}
.main-header.main-header--menu-opened {
    /*min-height: 100vh;
    height: 100vh;
    justify-content: flex-start;*/
}


/*@media (min-width: 1301px) and (min-height: 600px) {
    .top-fixed {
        position: fixed;
        top: 48px;
        left: 24px;
        min-width: 210px;
    }
    .bottom-fixed {
        position: fixed;
        left: 24px;
        bottom: 48px;
    }
}*/
/*@media (min-width: 1301px) and (min-height: 600px) {
    .main-header {
        position: sticky;
        top: 0;
    }
}*/
@media (min-width: 1301px) {
    .main-header {
        position: sticky;
        top: 0;
    }
}
@media (min-width: 1301px) and (max-height: 600px) {
    .main-header {
        height: 100%;
    }
}


.main-header .logo-wrapper {
    margin: 24px;
}


.copyright {
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: rgba(255,255,255,0.5);
}
.main-header .copyright {
    margin: 0 24px;
}


.menu-button {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
}
.main-header .menu-button {
    margin-right: 24px;
}
.menu-button:before,
.menu-button:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 21.5px;
    height: 1.5px;
    background-color: white;
    border-radius: 1px;
    transition: transform, .5s;
}
.menu-button:before {
    transform: translateY(10px);
}
.menu-button:after {
    transform: translateY(20px);
}
.menu-button--active:before {
    transform: rotate(-45deg) translate(-7px, 13px);
}
.menu-button--active:after {
    transform: rotate(45deg) translate(12px, 6px);
}


@media (max-width: 1300px) {
    .main-header .group-wrapper {
        display: flex;
        justify-content: left;
        align-items: center;
        position: relative;
        z-index: 301;
    }
}