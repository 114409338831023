.popup-background {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1001;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.5);
}
.popup-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.popup-wrapper {
    overflow: visible;
}
.popup {
    position: relative;
    padding: 40px 77px 38px;
    background-color: #FFFFFF;
    box-shadow: 0 8px 24px rgba(177, 183, 177, 0.35);
    border-radius: 24px;
    width: 479px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1002;
}
.popup-content-wrapper .popup {
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
}
.popup-ledge {
    background-color: #FFFFFF;
    box-shadow: 0 8px 24px rgba(177, 183, 177, 0.35);
    height: 24px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    margin: 0 32px;
}
.popup-content-wrapper .popup-ledge {
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
}
.popup-title {
    font-weight: 800;
    font-size: 26px;
    line-height: 36px;
    color: #181827;
    margin-top: 12px;
    text-align: center;
}
.popup-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #999999;
    text-align: center;
    margin: 8px 0 40px;
}
.popup-header {
    display: flex;
    align-items: center;
    width: 479px;
    padding: 32px 32px 62px;
    margin-bottom: -30px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: #9A975A;
    background-image: url("data:image/svg+xml,%3Csvg width='132' height='121' viewBox='0 0 132 121' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='115.5' cy='76.5' r='76.5' fill='white' fill-opacity='0.1'/%3E%3Ccircle cx='12' cy='12' r='12' fill='white' fill-opacity='0.1'/%3E%3C/svg%3E%0A");
    background-position: bottom right;
    background-repeat: no-repeat;
}
.popup-header-title {
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    color: white;
    margin-left: 12px;
}
.popup-header-date-wrapper {
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin-left: auto;
}
.popup-header-time {
    text-align: right;
}
.popup.popup--patient-notification {
    padding-left: 48px;
    padding-right: 48px;
}