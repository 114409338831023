.main-wrapper {
    display: flex;
    background-color: #181827;
    min-height: 100vh;
}
@media (max-width: 1300px) {
    .main-wrapper {
        flex-direction: column;
    }
}
/*.main-wrapper--menu-opened {
    height: 100vh;
}*/

.main-layout {
    position: relative;
    background-color: #F7FBF9;
    width: 100%;
    border-top-left-radius: 24px;
    margin-left: -32px;
    /*min-height: 100vh;*/
    /*height: auto;*/
    /*overflow: auto;*/
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}
@media (max-width: 1300px) {
    .main-layout {
        height: auto;
        margin-left: 0;
        min-height: 100vh;
        border-radius: 0;
    }
}

.page-header {
    display: flex;
    justify-content: space-between;
    padding: 48px 40px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 24px rgba(233, 235, 233, 0.35);
    border-top-left-radius: 24px;
}
@media (max-width: 1300px) {
    .page-header {
        padding: 24px;
        border-bottom-left-radius: 24px;
    }
}
.page-content-wrapper {
    padding: 48px 40px;
}
.page-content-inner-wrapper {
    max-width: 1102px;
    margin: 0 auto;
}

.wave-effect {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 111;
}
@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

.tab-button.update_email_send {
    padding: 13px 6px;
}
@keyframes move-left {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes move-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
@keyframes move-top {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}
@keyframes move-bottom {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
.highlight {
    color: #9A975A;
}
body .iti-mobile .intl-tel-input .country-list {
    max-height: 200px;
}

.radio-button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 400px;
    background: transparent;
    padding: 0;
    margin-bottom: 12px;
}
.radio-button:last-of-type {
    margin-bottom: 0;
}
.radio-button-img-wrapper {
    width: 32px;
    height: 32px;
    background-color: #C9C9C9;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}
.radio-button--active .radio-button-img-wrapper,
.radio-button:hover .radio-button-img-wrapper {
    background-color: #94B4A4;
}
.radio-button-img-wrapper svg {
    width: 16px;
    height: 16px;
}
.mail path:first-of-type {
    fill: white;
    stroke: white;
}
.mail path:last-of-type {
    stroke: #C9C9C9;
}
.radio-button-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #181827;
}
.radio-button--active .radio-button-checkmark:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #94B4A4;
    border-radius: 50%;
}
.radio-button-checkmark {
    position: relative;
    margin-left: auto;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #C9C9C9;
}